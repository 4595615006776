.react-date-picker__wrapper {
  border: none;
}
.react-date-picker__inputGroup,
.react-date-picker__inputGroup__divider,
.react-date-picker__range-divider,
.react-date-picker__button {
  display: none;
}

.react-date-picker__calendar {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  z-index: 2;
}

.react-calendar {
  border: 1px solid #ddd;
}

.react-calendar__tile--now:not(.react-calendar__tile--active){
  background: #ddd;
}
